import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import { AuthWrapper } from "./components/auth/AuthWrapper";
import ReactGA from "react-ga4";

const providerConfig: Auth0ProviderOptions = {
  domain: String(process.env.REACT_APP_AUTH0_DOMAIN),
  clientId: String(process.env.REACT_APP_AUTH0_CLIENT_ID),
  connection: String(process.env.REACT_APP_AUTH0_CONNECTION),
  audience: String(process.env.REACT_APP_AUTH0_AUDIENCE),
  redirectUri: `${window.location.origin}/collection`,
  skipRedirectCallback: window.location.search.includes("addToSlack"),
  cacheLocation: "localstorage",
  useRefreshTokens: true
};

const TRACKING_ID = "G-7DP3R8570D";
ReactGA.initialize(TRACKING_ID);

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider {...providerConfig}>
      <AuthWrapper />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if (process.env.NODE_ENV === "production") {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}
