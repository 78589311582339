type Props = {
  onClose: () => void;
  children: React.ReactNode;
};
export const Modal = ({ onClose, children }: Props) => {
  return (
    <div
      className="fixed inset-0 z-10 overflow-y-auto bg-gray-500/50"
      role="dialog"
    >
      <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl rtl:text-right my-8 align-middle max-w-md min-w-[270px] w-full sm:p-6">
          <div className="absolute right-3 top-2">
            <button
              type="button"
              className="-mr-1 flex p-2 rounded-md text-gray-800 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              onClick={() => {
                onClose();
              }}
            >
              <span className="sr-only">Dismiss</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
