import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import "./App.css";
import { appRoutes } from "./utils/routes";
import Navbar from "./components/layout/Navbar";
import Home from "./components/pages/Home";
import NotFound from "./components/layout/NotFound";
import { AppContext } from "./contexts/AppContext";
import About from "./components/pages/About";
import Collection from "./components/pages/Collection/Collection";
import Settings from "./components/pages/Settings/Settings";
import { Auth0ContextInterface, useAuth0 } from "@auth0/auth0-react";
import { useAccount } from "./hooks/useAccount";
import { Banner } from "./components/layout/Banner";
import { AddToSlack } from "./components/layout/AddToSlack";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { Privacy } from "./components/pages/Privacy";
import { Footer } from "./components/layout/Footer";
import { Terms } from "./components/pages/Terms";
import { Support } from "./components/pages/Support";
import LogRocket from "logrocket";
import { isInternalUser } from "./utils/users";
import { useHubspotChat } from "./hooks/useHubspotChat";
import { AdminBanner } from "./components/layout/AdminBanner";
import { Admin } from "./components/pages/Admin";
import { MarketOverview } from "./components/pages/Market/MarketOverview";

const App = () => {
  const { account, doAccountRefresh, isLoading, reloadAccount } = useAccount();

  const { isAuthenticated, error } = useAuth0();
  useHubspotChat("23348753", account);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search
    });
  }, []);

  useEffect(() => {
    if (!account?.user || isInternalUser(account?.user?.email)) {
      return;
    }

    LogRocket.init("x01zut/emojibox");
    LogRocket.identify(account.user.slackId, {
      name: account.user.userName,
      email: account.user.email,
      teamName: account.team.teamName,
      isBotInstalled: !!account.installation
    });
  }, [account]);

  return (
    <AppContext.Provider
      value={{
        account,
        isAccountLoading: isLoading,
        refreshAccount: async (auth0: Auth0ContextInterface) => {
          await doAccountRefresh(auth0);
        },
        reloadAccount
      }}
    >
      <Router>
        <div className="flex flex-col min-h-screen bg-gray-100">
          {isInternalUser(account?.user?.email) && account?.team && (
            <AdminBanner />
          )}
          <Navbar />
          {isAuthenticated && account && !account?.installation && (
            <Banner
              title="Get started with EmojiBox by adding it to Slack"
              action={<AddToSlack buttonText="Add to Slack" />}
            />
          )}
          <Routes>
            <Route
              path={appRoutes.ROOT}
              element={
                isAuthenticated && !error ? (
                  <Navigate to={appRoutes.COLLECTION} replace />
                ) : (
                  <Home />
                )
              }
            />
            <Route path={appRoutes.MARKET} element={<MarketOverview />} />
            <Route path={appRoutes.COLLECTION + "*"} element={<Collection />} />
            <Route path={appRoutes.ABOUT} element={<About />} />
            <Route path={appRoutes.PRIVACY} element={<Privacy />} />
            <Route path={appRoutes.TERMS} element={<Terms />} />
            <Route path={appRoutes.SUPPORT} element={<Support />} />
            <Route path={appRoutes.ADMIN} element={<Admin />} />
            <Route path={appRoutes.SETTINGS + "/*"} element={<Settings />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </AppContext.Provider>
  );
};

export default App;
