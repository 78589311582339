import { Account } from "../api/types/account";
import ReactGA from "react-ga4";

export const trackEvent = (eventName: string, account?: Account) => {
  ReactGA.send({
    hitType: "event",
    eventAction: eventName,
    eventLabel: account?.user?.email,
    eventCategory: account?.team?.teamName,
    page: window.location.pathname + window.location.search
  });
};
