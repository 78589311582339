import { Auth0ContextInterface } from "@auth0/auth0-react";
import { createContext, useContext } from "react";
import { Account } from "../api/types/account";

export type AppContextType = {
  account: Account | undefined;
  isAccountLoading: boolean;
  refreshAccount: (auth0: Auth0ContextInterface) => Promise<void>;
  reloadAccount: () => Promise<void>;
};

export const AppContext = createContext<AppContextType>({
  account: undefined,
  isAccountLoading: false,
  refreshAccount: () => Promise.resolve(),
  reloadAccount: () => Promise.resolve()
});
export const useAppContext = () => useContext(AppContext);
