export const Spinner = ({ size = "16" }: { size?: "4" | "8" | "16" }) => {
  return (
    <div className="top-1/2 my-0 mx-auto block relative w-0 h-0">
      <div
        style={{
          borderTopColor: "transparent"
        }}
        className={`border-indigo-600 w-${size} h-${size} border-4 border-solid rounded-full animate-spin`}
      ></div>
    </div>
  );
};
