import { appRoutes } from "../../utils/routes";
import Header from "../layout/Header";

export const Support = () => {
  return (
    <div className="bg-gray-100 container mx-auto pb-10">
      <div className="mx-auto xs:px-6 lg:px-8">
        <Header area="Home" baseRoute={appRoutes.ROOT} subPage={"Support"} />

        <div className="text-md">
          <p className="pb-2 font-bold text-lg">
            Issues, questions or queries regarding EmojiBox?
          </p>
          <p className="pb-2">
            We'd love to hear from you, please allow up to 2 business days for
            us to respond.
          </p>
          <p>
            Drop us a line at{" "}
            <a
              className="text-indigo-600"
              href="mailto:hello@emojibox.app?subject=Support request"
            >
              hello@emojibox.app
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
