import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import {
  listNotifications,
  removeNotification
} from "../../../api/notificationApi";
import { NotificationSubscriptionDto } from "../../../api/types/notificationsubscription";
import { useTitle } from "../../../utils/useTitle";
import { Hashtag } from "../../icons/hashtag";
import { Spinner } from "../../layout/Spinner";

export const Notifications = () => {
  const [notificationSubscriptions, setNotificationSubscriptions] = useState<
    NotificationSubscriptionDto[] | undefined
  >();
  const auth0 = useAuth0();
  useTitle(`EmojiBox - Settings > Notifications`);

  useEffect(() => {
    (async () => {
      const notifications = await listNotifications(auth0);
      setNotificationSubscriptions(notifications);
    })();
  }, [auth0]);

  if (!notificationSubscriptions) {
    return (
      <div className="h-48 pb-10">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-bold text-gray-500">Subscribed Channels</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {notificationSubscriptions.length > 0 ? (
            <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
              {notificationSubscriptions?.map((n) => (
                <li
                  key={n.channelName}
                  className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                >
                  <div className="w-0 flex-1 flex items-center">
                    <Hashtag />
                    <span className="ml-2 flex-1 w-0 truncate">
                      {n.channelName}
                    </span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <button
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                      onClick={() => {
                        removeNotification(auth0, n.channelId);
                        setNotificationSubscriptions(
                          notificationSubscriptions.filter(
                            (ns) => ns.channelId !== n.channelId
                          )
                        );
                      }}
                    >
                      Remove
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <>
              <p>
                <strong>No channels are subscribed to notifications yet</strong>
              </p>
              <p>
                To subscribe simply type <strong>@EmojiBox subscribe</strong> in
                the desired channel
              </p>
            </>
          )}
        </dd>
      </div>
    </div>
  );
};
