import { FC } from "react";
import { Link } from "react-router-dom";

export interface IHeader {
  baseRoute: string;
  area: string;
  subPage?: string;
}

const Header: FC<IHeader> = ({ baseRoute, area, subPage }) => {
  return (
    <div className="my-5 py-2 w-full grid items-center ">
      <div className="flex items-center">
        {subPage ? (
          <>
            <Link
              to={{
                pathname: baseRoute
              }}
              className="text-gray-800 font-medium text-lg hover:underline"
            >
              {area}
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg> */}
            </Link>
            <span className="mx-3 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 fill-gray-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            <p className="text-indigo-500 font-bold text-lg">{subPage}</p>
          </>
        ) : (
          <span className="text-indigo-500 font-bold text-lg">{area}</span>
        )}
      </div>
    </div>
  );
};

export default Header;
