import { useEffect, useState } from "react";
import { PageMeta } from "../../api/types/page";

interface Props {
  pageMeta: PageMeta;
  objectName: string;
}
export const PageSummary = ({ pageMeta, objectName }: Props) => {
  const [firstItemCount, setFirstItemCount] = useState<number>(1);
  const [lastItemCount, setLastItemCount] = useState<number>(pageMeta.take);
  useEffect(() => {
    let firstItem = 1;
    let lastItem = pageMeta.take * pageMeta.page;
    if (pageMeta.page > 1) {
      firstItem += pageMeta.take * pageMeta.page - pageMeta.take;
    }
    if (pageMeta.page === pageMeta.pageCount || lastItem > pageMeta.itemCount) {
      lastItem = pageMeta.itemCount;
    }
    setFirstItemCount(firstItem);
    setLastItemCount(lastItem);
  }, [pageMeta]);

  return (
    <div className="flex flex-col items-center">
      {pageMeta.itemCount > 0 && (
        <>
          <span className="text-sm text-gray-700 py-3">
            Showing{" "}
            <span className="font-semibold text-gray-900">
              {firstItemCount}
            </span>{" "}
            to{" "}
            <span className="font-semibold text-gray-900">{lastItemCount}</span>{" "}
            of{" "}
            <span className="font-semibold text-gray-900">
              {pageMeta.itemCount}
            </span>{" "}
            {objectName}
          </span>
        </>
      )}
    </div>
  );
};
