import { Spinner } from "./Spinner";

export const Loader = () => {
  return (
    <div className="flex flex-col h-screen">
      <div className="w-full h-full fixed block top-0 left-0 z-50">
        <Spinner />
      </div>
    </div>
  );
};
