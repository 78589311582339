import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Location, useLocation } from "react-router-dom";
import { getEmoji, updateEmoji } from "../../../api/emojiApi";
import { createEmoji, deleteEmoji } from "../../../api/marketApi";
import { EmojiDto } from "../../../api/types/emoji";
import { useTitle } from "../../../utils/useTitle";
import { Spinner } from "../../layout/Spinner";
import Alert from "../../layout/Alert";

const Emoji = () => {
  const [emoji, setEmoji] = useState<EmojiDto>();
  const [notFound, setNotFound] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [alert, setAlert] = useState<string | undefined>(undefined);
  const [reload, setReload] = useState(true);
  const [descriptionValue, setDescriptionValue] = useState<string>();
  const auth0 = useAuth0();
  const location = useLocation();
  useTitle(`EmojiBox - View :${emoji?.emojiName}:`);

  useEffect(() => {
    if (!reload) return;

    (async () => {
      const emojiName = getEmojiFromUrl(location);
      if (!emojiName) {
        return;
      }
      const emoji = await getEmoji(auth0, emojiName);
      if (emoji) {
        setDescriptionValue(emoji?.description);
        setEmoji(emoji);
      } else {
        setNotFound(true);
      }
      setReload(false);
      setIsPublishing(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const publishEmoji = async () => {
    if (!emoji) {
      return;
    }
    setIsPublishing(true);
    await createEmoji(auth0, {
      emojiName: emoji.emojiName,
      imageUrl: emoji.imageUrl
    });
    setReload(true);
    setAlert(`🎉 :${emoji.emojiName}: has been shared with the world!`);
  };

  const unpublishEmoji = async () => {
    if (!emoji) {
      return;
    }
    setIsPublishing(true);
    await deleteEmoji(auth0, emoji.publishedEmojiId ?? 0);
    setReload(true);
    setAlert(`:${emoji.emojiName}: has been unshared`);
  };

  if (notFound) {
    return (
      <div className="pr-3">
        <h3 className="text-md font-bold pb-2">
          Hmm... 👀 that Emoji doesn't exist.
        </h3>
        <p className="text-sm">
          It might have been deleted from your team since it was added.
        </p>
      </div>
    );
  }

  if (!emoji) {
    return (
      <div className="pt-16 pb-32 w-full -ml-8">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <section>
        <div>
          <div className="p-3 sm:flex max-w-lg mx-auto overflow-hidden">
            <a
              href={emoji.imageUrl}
              download={emoji.emojiName}
              target="_blank"
              rel="noreferrer"
              className="w-full sm:w-1/3"
            >
              <div
                className="w-full bg-contain bg-no-repeat bg-center sm:bg-left h-44 sm:h-24 sm:max-h-44"
                style={{ backgroundImage: `url('${emoji.imageUrl}')` }}
              ></div>
            </a>

            <div className="w-full sm:w-2/3 p-4 md:p-4">
              <h1 className="text-2xl font-bold text-gray-800 break-all">
                {emoji.emojiName}
              </h1>

              {emoji.createdAt && (
                <p className="mt-2 text-xs text-gray-600 ">
                  Added on&nbsp;
                  {new Date(emoji.createdAt).toLocaleDateString()}
                </p>
              )}

              {descriptionValue && (
                <div className="flex mt-2 item-center">{descriptionValue}</div>
              )}
            </div>
          </div>
          {alert && (
            <Alert
              status="success"
              message={alert}
              handleAlertMessageClose={() => setAlert(undefined)}
            />
          )}
          <div className="w-full mt-8">
            {!isEditing && (
              <div className="w-full text-center mt-2">
                <button
                  className="mr-2 p-3 text-xs font-bold text-white uppercase transition-colors duration-200 transform bg-gray-800 rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                  onClick={() => setIsEditing(true)}
                >
                  {!descriptionValue ? (
                    <>Add description</>
                  ) : (
                    <>Edit description</>
                  )}
                </button>
                {isPublishing ? (
                  <div className="inline-block py-4 px-12">
                    <Spinner size="4" />
                  </div>
                ) : (
                  <>
                    {!emoji?.publishedEmojiId ? (
                      <button
                        className="p-3 text-xs font-bold border-gray-600 border-2 uppercase transition-colors duration-200 transform rounded hover:bg-gray-300 focus:outline-none focus:bg-gray-300"
                        onClick={() => publishEmoji()}
                      >
                        Publish Emoji
                      </button>
                    ) : (
                      <button
                        className="p-3 text-xs font-bold border-gray-600 border-2 uppercase transition-colors duration-200 transform rounded hover:bg-gray-300 focus:outline-none focus:bg-gray-300"
                        onClick={() => unpublishEmoji()}
                      >
                        Unpublish Emoji
                      </button>
                    )}
                  </>
                )}
              </div>
            )}
            {isEditing && (
              <>
                <div className="w-full max-w-lg mx-auto mt-4">
                  <label className="block mb-2 text-sm font-medium text-gray-600">
                    Description
                  </label>

                  <textarea
                    className="block w-full text-sm h-28 px-4 py-2 text-gray-600 bg-white border rounded-md focus:border-gray-400 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40"
                    onInput={(e) => {
                      setDescriptionValue(e.currentTarget.value);
                    }}
                    value={descriptionValue}
                  ></textarea>
                </div>
                <div className="w-full text-right mt-2">
                  <button
                    className="p-3 text-xs font-bold text-white uppercase transition-colors duration-200 transform bg-gray-800 rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                    onClick={() => {
                      setIsEditing(false);
                      updateEmoji(auth0, emoji.emojiName, {
                        description: descriptionValue ?? ""
                      });
                    }}
                  >
                    Save
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Emoji;

const getEmojiFromUrl = (location: Location): string | undefined => {
  const path = location.pathname.endsWith("/")
    ? location.pathname.substring(0, location.pathname.length)
    : location.pathname;
  const paths = path.split("/");
  return paths.pop() as string;
};
