import { apiClient } from "./apiClient";
import { Auth0ContextInterface, User } from "@auth0/auth0-react";
import { EmojiDto } from "./types/emoji";
import { Page } from "./types/page";
import { EmojiOptions } from "./types/emojiOptions";

export const refreshEmojis = async (
  auth0: Auth0ContextInterface<User>
): Promise<any | undefined> => {
  try {
    return await apiClient(auth0).fetchValue<any>("/emoji/refresh");
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

export const getEmojis = async (
  auth0: Auth0ContextInterface<User>,
  emojiOptions: EmojiOptions
): Promise<Page<EmojiDto> | undefined> => {
  emojiOptions.skip = (emojiOptions.page - 1) * emojiOptions.take;
  let qs = `page=${emojiOptions.page}&take=${emojiOptions.take}&orderProperty=${emojiOptions.orderProperty}&order=${emojiOptions.order}`;
  if (emojiOptions.searchText) {
    qs += `&searchText=${emojiOptions.searchText}`;
  }
  try {
    return await apiClient(auth0).fetchValue<Page<EmojiDto>>(`/emoji?${qs}`);
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

export const getEmoji = async (
  auth0: Auth0ContextInterface<User>,
  emojiName: string
): Promise<EmojiDto | undefined> => {
  try {
    return await apiClient(auth0).fetchValue<EmojiDto>(`/emoji/${emojiName}`);
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const updateEmoji = async (
  auth0: Auth0ContextInterface<User>,
  emojiName: string,
  emojiUpdateDto: { description: string; publicEmojiId?: number }
): Promise<EmojiDto | undefined> => {
  try {
    const response = await apiClient(auth0).fetch(`/emoji/${emojiName}`, {
      body: JSON.stringify(emojiUpdateDto),
      method: "POST"
    });
    if (response.ok) {
      return;
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

export const downloadEmojiZip = async (auth0: Auth0ContextInterface<User>) => {
  const response = await apiClient(auth0).fetch(`/emoji/export`);
  const zipContent = await response.blob();
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(zipContent);
  link.setAttribute("download", `emojibox-export.zip`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
