import { withAuthenticationRequired } from "@auth0/auth0-react";
import { FC } from "react";
import { Location, useLocation } from "react-router-dom";
import { appRoutes } from "../../../utils/routes";
import Header from "../../layout/Header";
import { Loader } from "../../layout/Loader";
import SideNav from "../../layout/SideNav";
import { capitalizeFirstLetter } from "../../../utils/string.utils";
import { Overview } from "./Overview";
import { Notifications } from "./Notifications";
import { Installation } from "./Installation";

const Settings: FC = () => {
  const location = useLocation();
  const page = getPage(location);

  return (
    <div className="bg-gray-100 min-h-[60%] mb-10">
      <div className="container mx-auto">
        <Header area="Settings" baseRoute={appRoutes.SETTINGS} subPage={page} />
        <div>
          <section>
            <div className="sm:flex sm:-mx-2">
              <SideNav
                currentPage={page}
                pages={[
                  { name: "Notifications", route: appRoutes.NOTIFICATIONS },
                  { name: "Installation", route: appRoutes.INSTALLATION }
                ]}
              />

              <div className="bg-white shadow overflow-hidden sm:rounded-lg sm:mx-6 w-full">
                {page === undefined && <Overview />}
                {page === "Notifications" && <Notifications />}
                {page === "Installation" && <Installation />}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(Settings, {
  onRedirecting: () => <Loader />
});

const getPage = (location: Location): string | undefined => {
  const paths = location.pathname.split("/");
  if (paths?.length > 2) {
    return capitalizeFirstLetter(paths.pop() as string);
  }
  return;
};
