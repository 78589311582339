import { apiClient } from "./apiClient";
import { Auth0ContextInterface, User } from "@auth0/auth0-react";
import { NotificationSubscriptionDto } from "./types/notificationsubscription";

export const listNotifications = async (
  auth0: Auth0ContextInterface<User>
): Promise<NotificationSubscriptionDto[] | undefined> => {
  try {
    return await apiClient(auth0).fetchValue<NotificationSubscriptionDto[]>(
      "/notificationsubscription"
    );
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

export const removeNotification = async (
  auth0: Auth0ContextInterface<User>,
  channelId: string
): Promise<void> => {
  try {
    const response = await apiClient(auth0).fetch(
      `/notificationsubscription/${channelId}`,
      {
        method: "DELETE"
      }
    );
    if (response.ok) {
      return;
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};
