import { Badge, Button, Modal } from "flowbite-react";
import {
  MegaphoneIcon,
  PencilSquareIcon,
  SquaresPlusIcon
} from "@heroicons/react/20/solid";

export const InstallationSuccessModal = ({
  onClose
}: {
  onClose: () => void;
}) => {
  return (
    <Modal show={true} onClose={onClose}>
      <Modal.Header>
        Nice work, EmojiBox is installed and ready to go!
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <p className="text-base leading-relaxed text-gray-600">
            Here's a few tips and tricks to get you started:
          </p>
          <div className="grid pt-2 text-left">
            <div>
              <div className="mb-8">
                <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                  <MegaphoneIcon className="w-5 h-5 text-indigo-600 mr-4" />
                  Setup your #Emoji channel{" "}
                  <Badge className="ml-2" color="indigo">
                    Recommended
                  </Badge>
                </h3>
                <p className="text-gray-500">
                  Type{" "}
                  <span className="text-indigo-600 font-bold">
                    @EmojiBox subscribe
                  </span>{" "}
                  in a Slack channel that suits your team & let EmojiBox take
                  care of keeping your team up to date with Emoji which are
                  added!
                </p>
              </div>
              <div className="mb-8">
                <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                  <SquaresPlusIcon className="w-5 h-5 text-indigo-600 mr-4" />
                  Expand your Emoji collection
                </h3>
                <p className="text-gray-500">
                  Choose from over 50,000 Emoji shared by our community and grow
                  your Emoji collection. Get started in Slack by typing{" "}
                  <span className="text-indigo-600 font-bold">
                    @EmojiBox find {"<"}any emoji name{">"}
                  </span>{" "}
                </p>
              </div>
              <div className="mb-8">
                <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                  <PencilSquareIcon className="w-5 h-5 text-indigo-600 mr-4" />
                  Start documenting your Emoji
                </h3>
                <p className="text-gray-500">
                  Add descriptions to those in-joke Emojis, your team can look
                  them up straight from Slack by going{" "}
                  <span className="text-indigo-600 font-bold">
                    @EmojiBox :any-emoji-name:
                  </span>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="text-center mx-auto">
        <Button onClick={onClose}>Awesome, got it!</Button>
      </Modal.Footer>
    </Modal>
  );
};
