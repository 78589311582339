import { useAppContext } from "../../../contexts/AppContext";
import { useTitle } from "../../../utils/useTitle";
import { Spinner } from "../../layout/Spinner";

export const Overview = () => {
  const { account } = useAppContext();
  useTitle(`EmojiBox - Settings > Overview`);

  if (!account) {
    return (
      <div className="h-48 pb-10">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="border-t border-gray-200">
      <dl>
        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-bold text-gray-500">Slack Workspace</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {account?.team.teamName}
          </dd>
        </div>
        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-bold text-gray-500">Username</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {account?.user.userName}
          </dd>
        </div>
        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-bold text-gray-500">Email address</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {account?.user.email}
          </dd>
        </div>
        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-bold text-gray-500">Active since</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {new Date(account?.user.createdAt ?? "").toLocaleDateString()}
          </dd>
        </div>
      </dl>
    </div>
  );
};
