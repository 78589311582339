export const getInitials = (fullName: string | undefined) => {
  return fullName
    ? fullName
        .split(" ")
        .map((n) => n[0])
        .join("")
    : "?";
};

const internalEmails = ["h1bay1@gmail.com", "harrison@emojibox.app"];
export const isInternalUser = (email: string | undefined) => {
  return internalEmails.some((i) => i.toLowerCase() === email?.toLowerCase());
};
