export const capitalizeFirstLetter = (input: string) => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const compareStrings = (a: any, b: any, property: any) => {
  function compareProperty(property: any) {
    const ap = a[property];
    const bp = b[property];

    return !(ap || bp) ? 0 : !ap ? -1 : !bp ? 1 : ap.localeCompare(bp);
  }

  return compareProperty(property);
};
