import { FC, useCallback, useEffect, useState } from "react";
import { Loader } from "../../layout/Loader";
import { useEmojis } from "../../../hooks/useEmojis";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { appRoutes } from "../../../utils/routes";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useTitle } from "../../../utils/useTitle";
import { Modal } from "../../layout/Modal";
import EmojiModal from "./EmojiModal";
import debounce from "lodash.debounce";
import { Spinner } from "../../layout/Spinner";
import { useAppContext } from "../../../contexts/AppContext";
import { downloadEmojiZip } from "../../../api/emojiApi";
import { InstallationSuccessModal } from "./InstallationSuccessModal";
import { Button, Pagination } from "flowbite-react";
import { PageSummary } from "../../layout/PageSummary";

const Collection: FC = () => {
  const { emojis, pageMeta, isLoading, emojiOptions, setEmojiOptions } =
    useEmojis();
  const [sortMode, setSortMode] = useState("alphabetical");
  const [showSort, setShowSort] = useState(false);
  const [installationSuccess, setInstallationSuccess] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showEmoji, setShowEmoji] = useState<boolean>();
  const [isDownloading, setIsDownloading] = useState(false);
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const location = useLocation();
  const { account } = useAppContext();
  const auth0 = useAuth0();

  useTitle(`EmojiBox - Collection`);

  useEffect(() => {
    const pathName = location.pathname.toLowerCase();
    if (pathName.endsWith("collection/") || pathName.endsWith("collection")) {
      return;
    }
    setShowEmoji(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const installationSuccess = searchParams.get("installationSuccess");
    if (installationSuccess === "true") {
      setInstallationSuccess(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debounceEmojiLookup = debounce((value) => {
    setEmojiOptions((a) => {
      return { ...a, searchText: value, page: 1 };
    });
  }, 1000);

  const searchHandler = useCallback((event: any) => {
    setSearchTerm(event.target.value);
    setSearchLoading(true);
    debounceEmojiLookup(event.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSearchLoading(false);
  }, [emojis]);

  useEffect(() => {
    setShowSort(false);
    let orderProperty = "emojiName";
    let order = "ASC";
    if (sortMode === "alphabetical") {
      orderProperty = "emojiName";
    } else if (sortMode === "recent") {
      orderProperty = "createdAt";
      order = "DESC";
    } else if (sortMode === "descriptions") {
      orderProperty = "description";
      order = "DESC";
    }

    if (emojiOptions.orderProperty === orderProperty) {
      return;
    }
    setEmojiOptions((a) => {
      return { ...a, orderProperty, order };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortMode]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="bg-gray-100">
      <div className="pb-4 mx-auto container">
        {installationSuccess && (
          <InstallationSuccessModal
            onClose={() => {
              setInstallationSuccess(false);
              navigate(appRoutes.COLLECTION);
            }}
          />
        )}
        {showEmoji && (
          <Modal
            onClose={() => {
              setShowEmoji(false);
              navigate(appRoutes.COLLECTION);
            }}
            children={<EmojiModal />}
          />
        )}
        {account?.installation?.dateInstalled && (
          <>
            <section className="w-full pb-4 mt-4 mx-auto rounded-md rounded-b-none grid shadow-md  sm:grid-cols-3 sm:gap-2 bg-white p-3 border-gray-300 border-b">
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg
                    className="w-5 h-5 text-gray-400"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>

                <input
                  type="text"
                  className="w-full py-3 pl-10 pr-4 text-gray-700 bg-gray-100 border border-gray-300 rounded-md focus:border-gray-400 focus:outline-none focus:ring"
                  placeholder="Search"
                  value={searchTerm}
                  onInput={searchHandler}
                />
              </div>
              <div></div>

              <div className="relative inline-block text-right">
                <div className="relative inline-block text-right">
                  <button
                    type="button"
                    className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 mt-4"
                    id="menu-button"
                    onClick={() => setShowSort(!showSort)}
                  >
                    Sort order
                    <svg
                      className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  className={`origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${
                    !showSort ? "hidden" : ""
                  }`}
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                >
                  <div className="py-1" role="none">
                    <button
                      className={`block px-4 py-2 text-sm ${
                        sortMode === "alphabetical"
                          ? "text-gray-900 font-bold"
                          : "text-gray-500"
                      }`}
                      role="menuitem"
                      id="menu-item-0"
                      onClick={() => {
                        setSortMode("alphabetical");
                      }}
                    >
                      Alphabetical
                    </button>

                    <button
                      className={`block px-4 py-2 text-sm ${
                        sortMode === "recent"
                          ? "text-gray-900 font-bold"
                          : "text-gray-500"
                      }`}
                      role="menuitem"
                      id="menu-item-1"
                      onClick={() => {
                        setSortMode("recent");
                      }}
                    >
                      Most Recent
                    </button>

                    <button
                      className={`block px-4 py-2 text-sm ${
                        sortMode === "descriptions"
                          ? "text-gray-900 font-bold"
                          : "text-gray-500"
                      }`}
                      role="menuitem"
                      id="menu-item-3"
                      onClick={() => {
                        setSortMode("descriptions");
                      }}
                    >
                      Description
                    </button>
                  </div>
                </div>
                <div className="relative inline-block text-right mx-4 ">
                  <button
                    onClick={async () => {
                      setIsDownloading(true);
                      await downloadEmojiZip(auth0);
                      setIsDownloading(false);
                    }}
                    className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 mt-4 disabled:text-gray-400"
                    disabled={isDownloading}
                  >
                    {isDownloading ? "Exporting..." : "Export all"}
                  </button>
                </div>
              </div>
            </section>
            <div className="bg-white shadow-md rounded-md rounded-t-none py-4 md:py-7 px-4 md:px-8 xl:px-10">
              <div className="items-center">
                {emojis && emojis.length > 0 && !searchLoading && (
                  <div className="grid grid-cols-3 gap-4 lg:grid-cols-6">
                    {emojis
                      ?.filter((e) => !e.imageUrl.startsWith("alias"))
                      .map((e) => (
                        <div
                          key={e.emojiName}
                          className="text-xs text-center mx-auto p-2 cursor-pointer hover:bg-gray-300"
                          onClick={() => {
                            setShowEmoji(true);
                            navigate(`${appRoutes.COLLECTION}/${e.emojiName}`);
                          }}
                        >
                          <img
                            alt={e.emojiName}
                            className="inline-block"
                            src={e.imageUrl}
                            width="80"
                            height="auto"
                          />
                          <p className="break-all">{e.emojiName}</p>
                        </div>
                      ))}
                  </div>
                )}
                {emojis && emojis.length === 0 && searchTerm && !searchLoading && (
                  <div className="text-center">
                    Nothing found for <strong>{searchTerm}</strong> in your
                    collection
                    <div className="inline-block">
                      <Button
                        className="mx-2"
                        onClick={() => {
                          navigate(`${appRoutes.MARKET}?search=${searchTerm}`);
                        }}
                      >
                        Search all emojis
                      </Button>
                    </div>
                  </div>
                )}
                {searchLoading && (
                  <div className="text-center pt-16 pb-32">
                    <Spinner />
                  </div>
                )}
                {pageMeta && pageMeta.itemCount > 0 && !searchLoading && (
                  <div className="flex flex-col items-center mt-8">
                    {pageMeta.itemCount > pageMeta.take && (
                      <>
                        <div className="hidden md:inline-block">
                          <Pagination
                            currentPage={+pageMeta.page}
                            onPageChange={(page) => {
                              setEmojiOptions((a) => ({
                                ...a,
                                page: page
                              }));
                            }}
                            showIcons={true}
                            totalPages={pageMeta.pageCount}
                          />
                        </div>
                        <div className="inline-block md:hidden">
                          <Pagination
                            currentPage={+pageMeta.page}
                            onPageChange={(page) => {
                              setEmojiOptions((a) => ({
                                ...a,
                                page: page
                              }));
                            }}
                            showIcons={true}
                            totalPages={pageMeta.pageCount}
                            layout="navigation"
                          />
                        </div>
                      </>
                    )}

                    <PageSummary pageMeta={pageMeta} objectName={"Emoji"} />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {!account?.installation?.dateInstalled && (
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 py-6">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                Getting started
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              Your Emojis will appear here
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
              Once you've connected to EmojiBox you'll see your collection here,
              you'll be able to add descriptions and organise your emojis.
            </p>
          </div>
        )}
      </div>
      <p></p>
    </div>
  );
};

export default withAuthenticationRequired(Collection, {
  onRedirecting: () => <Loader />
});
