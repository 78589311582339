import { Link, useLocation } from "react-router-dom";
import { appRoutes } from "../../utils/routes";
import { useMemo } from "react";

export const Footer = () => {
  const location = useLocation();
  const isInHome = useMemo(() => {
    return location.pathname === appRoutes.ROOT;
  }, [location.pathname]);

  return (
    <footer className={isInHome ? "bg-indigo-700" : "bg-gray-100"}>
      <div className="container pt-4 pb-8 mx-auto">
        <div className="flex flex-col items-center sm:flex-row sm:justify-between">
          <p className={`text-sm ${isInHome ? "text-white" : "text-gray-400"}`}>
            Made in NZ, &copy; 2022
          </p>

          <div className="flex mt-3 -mx-2 sm:mt-0">
            <Link
              to={appRoutes.ABOUT}
              className={`mx-2 text-sm  transition-colors duration-300 ${
                isInHome ? "text-white" : "text-gray-400"
              } hover:text-opacity-80`}
            >
              {" "}
              About{" "}
            </Link>

            <Link
              to={appRoutes.PRIVACY}
              className={`mx-2 text-sm  transition-colors duration-300 ${
                isInHome ? "text-white" : "text-gray-400"
              } hover:text-opacity-80`}
            >
              {" "}
              Privacy{" "}
            </Link>
            <Link
              to={appRoutes.TERMS}
              className={`mx-2 text-sm  transition-colors duration-300 ${
                isInHome ? "text-white" : "text-gray-400"
              } hover:text-opacity-80`}
            >
              {" "}
              Terms{" "}
            </Link>

            <Link
              to={appRoutes.SUPPORT}
              className={`mx-2 text-sm  transition-colors duration-300 ${
                isInHome ? "text-white" : "text-gray-400"
              } hover:text-opacity-80`}
            >
              {" "}
              Support{" "}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
