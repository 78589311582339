import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getTeams } from "../../api/accountApi";
import { Team } from "../../api/types/team";
import { useAppContext } from "../../contexts/AppContext";
import { appRoutes } from "../../utils/routes";
import { isInternalUser } from "../../utils/users";
import Header from "../layout/Header";
import { Loader } from "../layout/Loader";

export const Admin = () => {
  const auth0 = useAuth0();
  const navigate = useNavigate();
  const { reloadAccount, account } = useAppContext();
  const [teams, setTeams] = useState<Team[]>();
  const [team, setTeam] = useState<Team>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!auth0 || !account?.user?.email) {
      return;
    }

    if (!isInternalUser(account.user.email)) {
      navigate(appRoutes.COLLECTION);
    }

    (async () => {
      const t = await getTeams(auth0);
      setTeams(t);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0, account]);

  useEffect(() => {
    if (!team) {
      return;
    }
    (async () => {
      setIsLoading(true);
      localStorage.setItem("x-team", JSON.stringify(team));
      await reloadAccount();
      setIsLoading(false);
      navigate(appRoutes.COLLECTION);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="bg-gray-100 container mx-auto">
      <Header area="Admin" subPage="Overview" baseRoute={appRoutes.ADMIN}>
        <div className="sm:justify-self-end space-x-2"></div>
      </Header>
      <table className="table-auto bg-white shadow rounded-lg w-full ">
        <thead>
          <tr className="py-4">
            <th className="border-b-2 px-4 py-2 whitespace-nowrap font-medium text-gray-700 text-left w-8">
              #
            </th>
            <th className="border-b-2 px-4 py-2 whitespace-nowrap font-medium text-gray-700 text-left">
              Team name
            </th>
            <th className="border-b-2 px-4 py-2 whitespace-nowrap font-medium text-gray-700 text-right"></th>
          </tr>
        </thead>
        <tbody>
          {teams?.map((team) => (
            <tr
              className="text-gray-700 hover:cursor-pointer hover:bg-gray-50"
              onClick={() => {
                setTeam(team);
              }}
            >
              <td className="border-b-2 p-4 text-gray-500">{team.teamId}</td>
              <td className="border-b-2 p-4 font-bold break-all">
                {team.teamName}{" "}
              </td>

              <td className="border-b-2 p-4 text-right">
                <button
                  onClick={() => {
                    setTeam(team);
                  }}
                  className="font-bold bg-gray-600 hover:bg-gray-800 text-white p-2 rounded-md"
                >
                  Login
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
