import { useEffect, useState } from "react";
import { EmojiOptions } from "../api/types/emojiOptions";
import { PageMeta } from "../api/types/page";
import { PublicEmojiOutput } from "../api/types/publicEmoji";
import { getEmojis } from "../api/marketApi";

export const usePublicEmojis = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [emojis, setEmojis] = useState<PublicEmojiOutput[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [emojiOptions, setEmojiOptions] = useState<EmojiOptions>({
    page: 1,
    take: 50,
    orderProperty: "createdAt",
    order: "DESC"
  });

  useEffect(() => {
    if (!emojiOptions) {
      return;
    }
    (async () => {
      let emojiPage = await getEmojis(emojiOptions);
      setEmojis([]);
      if (emojiPage && emojiPage.data?.length > 0) {
        setEmojis(emojiPage.data);
        setPageMeta(emojiPage.meta);
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emojiOptions]);

  return { emojis, isLoading, pageMeta, emojiOptions, setEmojiOptions };
};
