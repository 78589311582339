import { Link, useLocation } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";
import { appRoutes } from "../../utils/routes";

export const AdminBanner = () => {
  const { account } = useAppContext();
  const location = useLocation();

  if (location.pathname.startsWith(appRoutes.ADMIN)) {
    return <></>;
  }

  return (
    <div className={`bg-gray-600 text-white py-2`}>
      <div className="mx-auto text-center">
        <span className="inline break-all">
          Logged in as <strong>{account?.team?.teamName}</strong>,
        </span>{" "}
        <Link className="underline mt-1" to={appRoutes.ADMIN}>
          back to admin
        </Link>
      </div>
    </div>
  );
};
