import { Auth0ContextInterface, useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getAccount, refreshAccount } from "../api/accountApi";
import { Account } from "../api/types/account";

export const useAccount = () => {
  const [account, setAccount] = useState<Account>();
  const [needsRefreshing, setNeedsRefreshing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  const auth0 = useAuth0();

  useEffect(() => {
    if (!auth0.isAuthenticated) {
      return;
    }
    (async () => {
      let account = await getAccount(auth0);
      if (!account) {
        account = await refreshAccount(auth0);
      }
      setAccount(account);
      setIsLoading(false);

      if (account?.user?.lastLogin) {
        const lastLogin = Date.parse(account.user.lastLogin);
        if (lastLogin < new Date().setHours(0, 0, 0)) {
          setNeedsRefreshing(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0.isAuthenticated]);

  // TODO: extend into onboarding flow someday
  useEffect(() => {
    if (!needsRefreshing || !auth0.isAuthenticated) {
      return;
    }
    (async () => {
      await doAccountRefresh(auth0);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsRefreshing]);

  const doAccountRefresh = async (auth0: Auth0ContextInterface) => {
    const account = await refreshAccount(auth0);
    if (account) {
      setAccount(account);
    }
  };

  const reloadAccount = async () => {
    const account = await getAccount(auth0);
    if (account) {
      setAccount(account);
    }
  };

  return {
    account,
    setNeedsRefreshing,
    doAccountRefresh,
    isLoading,
    reloadAccount
  };
};
