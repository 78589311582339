import { FC } from "react";
import { appRoutes } from "../../utils/routes";
import { useTitle } from "../../utils/useTitle";
import Header from "../layout/Header";

const About: FC = () => {
  useTitle(`EmojiBox - About`);

  return (
    <div className="bg-gray-100 container mx-auto pb-10">
      <div className="mx-auto xs:px-6 lg:px-8">
        <Header area="Home" baseRoute={appRoutes.ROOT} subPage={"About"} />

        <div className="text-md">
          <p>Hello 👋, I'm Harrison.</p>
          <br />
          <p className="font-bold">
            I love Emoji, not the standard unicode ones though... the custom
            ones you find in Slack 😝
          </p>
          <br />
          <p>
            Slack Emoji when used just right can become an integral part of your
            team's culture. Company legends can be told with a simple reaction,
            1000 words conveyed with a couple of symbols. People communicate
            better, there's less misunderstanding and most importantly remote
            work becomes fun!
          </p>
          <br />
          <p>
            The way Slack has built out its Emoji features is terrific... but I
            think there's still some essential stuff you need to really be an
            "Emoji obsessed" workplace. That's where I hope EmojiBox can help
            you.
          </p>
          <br />
          <p>
            EmojiBox's mission in life is simple, to make your experience and
            use of Slack Emoji better. Easier ways to find what Emoji mean, a
            place in Slack to see when the latest Emoji land and more creative
            and clever ways to explore and manage your collection. EmojiBox
            doesn't do all the things I'm wanting it to do yet, but what you get
            now is the essentials to becoming "Emoji obsessed" I reckon.
          </p>
          <br />
          <p>
            Thanks for taking the time to install it and try it. I'm running
            EmojiBox for free at the moment but, over time I reckon I'll get it
            so good that you'll be willing to pay a small amount for some extra
            bits and pieces. Watch this space 🔥
          </p>
          <br />
          <p>
            If you have any suggestions or would like to ask a question about
            EmojiBox you can reach me at{" "}
            <a className="text-indigo-600" href="mailto:hello@emojibox.app">
              hello@emojibox.app
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
