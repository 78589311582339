import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getEmojis, refreshEmojis } from "../api/emojiApi";
import { EmojiDto } from "../api/types/emoji";
import { EmojiOptions } from "../api/types/emojiOptions";
import { PageMeta } from "../api/types/page";
import { useAppContext } from "../contexts/AppContext";

export const useEmojis = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [emojis, setEmojis] = useState<EmojiDto[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [emojiOptions, setEmojiOptions] = useState<EmojiOptions>({
    page: 1,
    take: 50,
    orderProperty: "emojiName",
    order: "ASC"
  });
  const auth0 = useAuth0();
  const { account } = useAppContext();

  useEffect(() => {
    if (!emojiOptions || !account) {
      return;
    }
    (async () => {
      let emojiPage = await getEmojis(auth0, emojiOptions);
      if (emojiPage && emojiPage.data?.length > 0) {
        setEmojis(emojiPage.data);
        setPageMeta(emojiPage.meta);
      } else {
        await refreshEmojis(auth0);
        emojiPage = await getEmojis(auth0, emojiOptions);
        if (emojiPage) {
          setEmojis(emojiPage.data);
          setPageMeta(emojiPage.meta);
        }
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emojiOptions, account]);

  return { emojis, isLoading, pageMeta, emojiOptions, setEmojiOptions };
};
