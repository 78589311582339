import { useAuth0 } from "@auth0/auth0-react";
import App from "../../App";
import { Loader } from "../layout/Loader";

export const AuthWrapper = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <App />
    </>
  );
};
