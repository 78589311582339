import { apiClient } from "./apiClient";
import { Auth0ContextInterface, User } from "@auth0/auth0-react";
import { Account } from "./types/account";
import { Team } from "./types/team";

export const getAccount = async (
  auth0: Auth0ContextInterface<User>
): Promise<Account | undefined> => {
  try {
    return await apiClient(auth0).fetchValue<Account>("/account");
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

export const getTeams = async (
  auth0: Auth0ContextInterface<User>
): Promise<Team[] | undefined> => {
  try {
    return await apiClient(auth0).fetchValue<Team[]>("/account/teams");
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

export const refreshAccount = async (
  auth0: Auth0ContextInterface<User>
): Promise<Account | undefined> => {
  try {
    return await apiClient(auth0).fetchValue<Account>("/account/refresh");
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

export const completeAuthFlow = async (
  auth0: Auth0ContextInterface<User>,
  code: string
): Promise<Account | undefined> => {
  try {
    const response = await apiClient(auth0).fetch("/account/completeauth", {
      body: JSON.stringify({ code: code }),
      method: "POST"
    });
    if (response.ok) {
      return;
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

export const uninstallBot = async (
  auth0: Auth0ContextInterface<User>
): Promise<Account | undefined> => {
  try {
    const response = await apiClient(auth0).fetch("/account/uninstall", {
      method: "POST"
    });
    if (response.ok) {
      return;
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};
