import { FC } from "react";

export type AlertStatus = "success" | "error" | "alert";

export interface IAlert {
  message: string;
  status: AlertStatus;
  handleAlertMessageClose: () => void;
}

const Alert: FC<IAlert> = ({ message, status, handleAlertMessageClose }) => {
  const { statusClass, fontClass } = styleToStatusMap[status];

  return (
    <div className={statusClass}>
      <h3 className={fontClass}>{message}</h3>
      <button className="justify-self-end" onClick={handleAlertMessageClose}>
        <img className="h-4 w-4" src="/icons/x.svg" alt="Close" />
      </button>
    </div>
  );
};

export default Alert;

const styleToStatusMap = {
  success: {
    fontClass: "font-medium text-green-900",
    statusClass:
      "mb-6 bg-green-50 border-green-700 py-2 px-4 my-2 w-full border-l-4 grid grid-flow-col items-center"
  },
  error: {
    fontClass: "font-medium text-red-700",
    statusClass:
      "mb-6 bg-red-50 border-red-700 py-2 px-4 my-2 w-full border-l-4 grid grid-flow-col items-center"
  },
  alert: {
    fontClass: "font-medium text-yellow-700",
    statusClass:
      "mb-6 bg-yellow-50 border-yellow-700 py-2 px-4 my-2 w-full border-l-4 grid grid-flow-col items-center"
  }
};
