import { usePublicEmojis } from "../../../hooks/usePublicEmojis";
import { useTitle } from "../../../utils/useTitle";
import { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { Loader } from "../../layout/Loader";
import { PageSummary } from "../../layout/PageSummary";
import { Pagination } from "flowbite-react";
import { Spinner } from "../../layout/Spinner";
import { useLocation } from "react-router-dom";

export const MarketOverview = () => {
  const { emojis, pageMeta, isLoading, emojiOptions, setEmojiOptions } =
    usePublicEmojis();
  const [sortMode, setSortMode] = useState("recent");
  const [showSort, setShowSort] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();

  useTitle(`EmojiBox - Find Emojis`);

  const debounceEmojiLookup = debounce((value) => {
    setEmojiOptions((a) => {
      return { ...a, searchText: value, page: 1 };
    });
  }, 1000);

  const searchHandler = useCallback((event: any) => {
    setSearchTerm(event.target.value);
    setSearchLoading(true);
    debounceEmojiLookup(event.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSearchLoading(false);
  }, [emojis]);

  useEffect(() => {
    setShowSort(false);
    let orderProperty = "emojiName";
    let order = "ASC";
    if (sortMode === "alphabetical") {
      orderProperty = "emojiName";
    } else if (sortMode === "recent") {
      orderProperty = "createdAt";
      order = "DESC";
    } else if (sortMode === "usage") {
      orderProperty = "usageCount";
      order = "DESC";
    }

    if (emojiOptions.orderProperty === orderProperty) {
      return;
    }
    setEmojiOptions((a) => {
      return { ...a, orderProperty, order };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortMode]);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const search = params.get("search");
      if (search) {
        setSearchTerm(search);
        setSearchLoading(true);
        debounceEmojiLookup(search);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="bg-gray-100">
      <div className="pb-4 mx-auto container">
        <>
          <div className="flex mt-4 max-w-2xl mx-auto">
            <label
              htmlFor="search-dropdown"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Sort By
            </label>
            <button
              id="dropdown-button"
              className="flex-shrink-0 z-10 inline-flex items-center py-4 px-4 text-sm font-medium text-center text-gray-100 bg-indigo-800 border border-gray-300 rounded-l-lg hover:bg-indigo-900"
              type="button"
              onClick={() => setShowSort(!showSort)}
            >
              Sort By
              <svg
                aria-hidden="true"
                className="w-4 h-4 ml-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
            <div
              id="dropdown"
              className={`z-10 absolute mt-14 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700  ${
                !showSort ? "hidden" : ""
              }`}
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdown-button"
              >
                <li>
                  <button
                    type="button"
                    className={`inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ${
                      sortMode === "recent"
                        ? "text-gray-900 font-bold"
                        : "text-gray-500"
                    }`}
                    onClick={() => {
                      setSortMode("recent");
                    }}
                  >
                    Most Recent
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={`inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ${
                      sortMode === "usage"
                        ? "text-gray-900 font-bold"
                        : "text-gray-500"
                    }`}
                    onClick={() => {
                      setSortMode("usage");
                    }}
                  >
                    Most Popular
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={`inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ${
                      sortMode === "alphabetical"
                        ? "text-gray-900 font-bold"
                        : "text-gray-500"
                    }`}
                    onClick={() => {
                      setSortMode("alphabetical");
                    }}
                  >
                    Alphabetical
                  </button>
                </li>
              </ul>
            </div>
            <div className="relative w-full">
              <input
                type="search"
                id="search-dropdown"
                className="block p-4 w-full z-20 text-md text-gray-900 bg-white rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-gray-300 focus:border-gray-300 placeholder:text-gray-700"
                placeholder="Search our crowd-sourced collection of over 50,000 custom Emojis"
                value={searchTerm}
                onInput={searchHandler}
                autoFocus
              />
            </div>
          </div>
          <div className="text-center py-2">
            <p className="text-xs text-gray-400">
              Psst... you can do this directly in Slack by going{" "}
              <span className="font-bold">
                @EmojiBox find {"<"}any emoji{">"}
              </span>
            </p>
          </div>
          <div className="bg-white shadow-md rounded-md py-4 md:py-7 px-4 md:px-8 xl:px-10">
            <div className="items-center">
              {emojis && emojis.length > 0 && !searchLoading && (
                <div className="grid grid-cols-3 gap-4 lg:grid-cols-6">
                  {emojis
                    ?.filter((e) => !e.imageUrl.startsWith("alias"))
                    .map((e) => (
                      <div
                        key={e.emojiName + e.createdAt}
                        className="text-xs text-center mx-auto p-2 cursor-pointer hover:bg-gray-300"
                        onClick={() => {
                          // download emoji from e.imageUrl without opening a new tab
                          const link = document.createElement("a");
                          link.href = e.imageUrl;
                          link.download = e.emojiName;
                          link.click();
                        }}
                      >
                        <div
                          className="inline-block h-[80px] w-[80px] bg-center bg-no-repeat bg-contain"
                          style={{
                            backgroundImage: `url(${e.imageUrl})`
                          }}
                        ></div>
                        {/* <img
                          alt={e.emojiName}
                          className="inline-block max-h-[100px]"
                          src={e.imageUrl}
                          width="80"
                          height="auto"
                        /> */}
                        <p className="break-all">{e.emojiName}</p>
                      </div>
                    ))}
                </div>
              )}
              {emojis && emojis.length === 0 && searchTerm && !searchLoading && (
                <div className="text-center">
                  <p>&#129335; No Emojis found, try a different term</p>
                </div>
              )}
              {searchLoading && (
                <div className="text-center pt-16 pb-32">
                  <Spinner />
                </div>
              )}
              {pageMeta &&
                pageMeta.itemCount > 0 &&
                !searchLoading &&
                emojis.length > 0 && (
                  <div className="flex flex-col items-center mt-8">
                    {pageMeta.itemCount > pageMeta.take && (
                      <>
                        <div className="hidden md:inline-block">
                          <Pagination
                            currentPage={+pageMeta.page}
                            onPageChange={(page) => {
                              setEmojiOptions((a) => ({
                                ...a,
                                page: page
                              }));
                            }}
                            showIcons={true}
                            totalPages={pageMeta.pageCount}
                          />
                        </div>
                        <div className="inline-block md:hidden">
                          <Pagination
                            currentPage={+pageMeta.page}
                            onPageChange={(page) => {
                              setEmojiOptions((a) => ({
                                ...a,
                                page: page
                              }));
                            }}
                            showIcons={true}
                            totalPages={pageMeta.pageCount}
                            layout="navigation"
                          />
                        </div>
                      </>
                    )}

                    <PageSummary pageMeta={pageMeta} objectName={"Emoji"} />
                  </div>
                )}
            </div>
          </div>
        </>
        {/* {!account?.installation?.dateInstalled && (
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 py-6">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                Getting started
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              Your Emojis will appear here
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
              Once you've connected to EmojiBox you'll see your collection here,
              you'll be able to add descriptions and organise your emojis.
            </p>
          </div>
        )} */}
      </div>
      <p></p>
    </div>
  );
};
