import { FC } from "react";
import { Link } from "react-router-dom";

export interface ISideNav {
  currentPage?: string;
  pages: ISideNavPage[];
}

export interface ISideNavPage {
  name: string;
  route: string;
}

const SideNav: FC<ISideNav> = ({ currentPage, pages }) => {
  return (
    <div className="space-y-3 sm:w-1/5 sm:px-2 sm:space-y-4 mb-6">
      {pages.map((page) => (
        <Link
          key={page.name}
          to={page.route}
          className={`block font-medium hover:underline ${
            currentPage === page.name ? "text-indigo-600" : "text-gray-500"
          }`}
        >
          {page.name}
        </Link>
      ))}
    </div>
  );
};

export default SideNav;
