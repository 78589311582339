export const appRoutes = {
  ROOT: "/",
  MARKET: "/market",
  COLLECTION: "/collection",
  ABOUT: "/about",
  PRIVACY: "/privacy",
  TERMS: "/terms",
  SUPPORT: "/support",
  SETTINGS: "/settings",
  INSTALLATION: "/settings/installation",
  NOTIFICATIONS: "/settings/notifications",
  ADMIN: "/admin"
};
