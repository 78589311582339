import { useMemo, useState } from "react";
import { getInitials } from "../../utils/users";
import { useAuth0 } from "@auth0/auth0-react";
import { appRoutes } from "../../utils/routes";
import { Link, useLocation } from "react-router-dom";
import { trackEvent } from "../../utils/ga.utils";
import { Events } from "../../utils/events.utils";

const Navbar = () => {
  const [userMenuExpanded, setUserMenuExpanded] = useState<boolean>(false);

  const toggleUserMenu = () => {
    setUserMenuExpanded(!userMenuExpanded);
  };

  const auth0 = useAuth0();
  const location = useLocation();

  const isInAccount = useMemo(() => {
    return (
      location.pathname.startsWith(appRoutes.SETTINGS) ||
      location.pathname.startsWith(appRoutes.COLLECTION)
    );
  }, [location.pathname]);

  const isInHome = useMemo(() => {
    return location.pathname === appRoutes.ROOT;
  }, [location.pathname]);

  const { loginWithRedirect, logout, user, isAuthenticated, isLoading } = auth0;

  return (
    <div>
      <nav className={`${isInHome ? "bg-indigo-700" : "bg-gray-900"}`}>
        <div className="mx-auto container">
          <div className="relative flex items-center justify-between h-16">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden"></div>
            <div className="flex-1 flex items-stretch justify-start">
              <Link
                to={{
                  pathname: "/"
                }}
              >
                <div className="flex-shrink-0 flex items-center ">
                  <img
                    className="lg h-12 w-auto mr-3 hidden sm:block"
                    src="/icon.png"
                    alt="icon"
                  />
                  <h1 className="text-xl text-white font-black inline-block">
                    EmojiBox
                  </h1>
                </div>
              </Link>
              <div className="mt-3">
                <div className="ml-3 relative font-bold text-white align-middle">
                  {isAuthenticated && isInAccount ? (
                    <div className="hidden sm:block">
                      <Link
                        to={appRoutes.COLLECTION}
                        className={`${
                          location.pathname.startsWith(appRoutes.COLLECTION)
                            ? "bg-gray-900"
                            : "text-gray-300"
                        } px-3 py-2 rounded-md text-md`}
                      >
                        Collection
                      </Link>

                      <Link
                        to={appRoutes.SETTINGS}
                        className={`${
                          location.pathname.startsWith(appRoutes.SETTINGS)
                            ? "bg-gray-900"
                            : "text-gray-300"
                        }  px-3 py-2 rounded-md text-md`}
                      >
                        Settings
                      </Link>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {isAuthenticated && (
              <div className="">
                {isInAccount ? (
                  <div className="text-center my-3 inline-block mx-4">
                    <Link
                      to={appRoutes.MARKET}
                      className={`text-white ${
                        location.pathname.startsWith(appRoutes.MARKET)
                          ? "bg-gray-900"
                          : "text-gray-300"
                      } text-gray-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-opacity-80 px-5 py-3`}
                    >
                      Find emojis
                    </Link>
                  </div>
                ) : (
                  <div className="text-center my-3 inline-block mx-4">
                    <Link
                      to={appRoutes.COLLECTION}
                      className={`text-white ${
                        location.pathname.startsWith(appRoutes.COLLECTION)
                          ? "bg-gray-900"
                          : "text-gray-300"
                      } text-gray-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-500 px-5 py-3`}
                    >
                      My collection
                    </Link>
                  </div>
                )}
                <div className="inline-block">
                  <button
                    type="button"
                    className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white ml-auto"
                    id="user-menu-button"
                    onClick={toggleUserMenu}
                  >
                    <span className="sr-only">Open user menu</span>
                    <div className="w-12 h-12 relative flex justify-center items-center rounded-full bg-gray-500 text-xl text-white uppercase">
                      {getInitials(user?.name)}
                    </div>
                  </button>
                </div>

                {userMenuExpanded && (
                  <div
                    className="origin-top-right absolute right-0 top-16 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 col-span-2"
                    role="menu"
                  >
                    <button
                      id="sign-out"
                      onClick={() =>
                        logout({ returnTo: window.location.origin })
                      }
                      className="block px-4 py-2 text-sm text-gray-700"
                    >
                      Sign out
                    </button>
                  </div>
                )}
              </div>
            )}

            {!isAuthenticated && !isLoading && (
              <div>
                {!location.pathname.startsWith(appRoutes.MARKET) ? (
                  <div className="text-center my-3 inline-block mx-4">
                    <Link
                      to={appRoutes.MARKET}
                      className={`text-white transition-all duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:text-opacity-80 px-5 py-2.5`}
                    >
                      Find emojis
                    </Link>
                  </div>
                ) : (
                  <div className="text-center my-3 inline-block mx-4">
                    <Link
                      to={appRoutes.ROOT}
                      className={`text-white transition-all duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-500 px-5 py-2.5`}
                    >
                      Home
                    </Link>
                  </div>
                )}
                <button
                  id="sign-in"
                  onClick={() => {
                    trackEvent(Events.SIGNIN_WITH_SLACK, undefined);
                    loginWithRedirect();
                  }}
                  className="h-10 px-5 my-2 text-gray-700 transition-colors duration-150 bg-white rounded-lg focus:shadow-outline hover:bg-opacity-80"
                >
                  Sign in
                </button>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
