import React, { useEffect } from "react";
import { Account } from "../api/types/account";

export const useHubspotChat = (portalId: string, profile?: Account) => {
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [activeConversation, setActiveConversation] = React.useState(false);
  const eventRef = React.useRef<(payload: any) => void>();
  const win = window as any;

  useEffect(() => {
    if (!profile) {
      return;
    }

    win.hsConversationsOnReady = [
      () => {
        setHasLoaded(true);
      }
    ];

    let script = document.createElement("script");
    script.src = `//js.hs-scripts.com/${portalId}.js`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      win.hsConversationsOnReady = [];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    eventRef.current = (payload: any) => {
      setActiveConversation(payload.conversation.conversationId);
    };

    if (hasLoaded) {
      win.HubSpotConversations.on("conversationStarted", eventRef.current);
      if (profile) {
        win.hsConversationSettings = {
          identificationEmail: profile?.user?.email
        };
        const _hsq = (win._hsq = win._hsq || []);
        _hsq.push([
          "identify",
          {
            email: profile?.user.email
          }
        ]);
      }
    }

    return () => {
      win.HubSpotConversations.off("conversationStarted", eventRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoaded]);

  const openHandler = React.useCallback(() => {
    if (hasLoaded) {
      win.HubSpotConversations.widget.open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoaded]);

  const closeHandler = React.useCallback(() => {
    if (hasLoaded) {
      win.HubSpotConversations.widget.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoaded]);

  return {
    hasLoaded,
    activeConversation,
    openHandler,
    closeHandler
  };
};
