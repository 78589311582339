import { apiClient } from "./apiClient";
import { Auth0ContextInterface, User } from "@auth0/auth0-react";
import { EmojiDto } from "./types/emoji";
import { Page } from "./types/page";
import { EmojiOptions } from "./types/emojiOptions";
import { PublicEmojiInput, PublicEmojiOutput } from "./types/publicEmoji";

export const getEmojis = async (
  emojiOptions: EmojiOptions
): Promise<Page<PublicEmojiOutput> | undefined> => {
  emojiOptions.skip = (emojiOptions.page - 1) * emojiOptions.take;
  let qs = `page=${emojiOptions.page}&take=${emojiOptions.take}&orderProperty=${emojiOptions.orderProperty}&order=${emojiOptions.order}`;
  if (emojiOptions.searchText) {
    qs += `&searchText=${emojiOptions.searchText}`;
  }
  try {
    return await apiClient(undefined).fetchValue<Page<PublicEmojiOutput>>(
      `/market/public-emoji/?${qs}`
    );
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

export const getEmoji = async (
  auth0: Auth0ContextInterface<User>,
  emojiName: string
): Promise<PublicEmojiOutput | undefined> => {
  try {
    return await apiClient(auth0).fetchValue<PublicEmojiOutput>(
      `/market/public-emoji/${emojiName}`
    );
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const createEmoji = async (
  auth0: Auth0ContextInterface<User>,
  createEmojiDto: PublicEmojiInput
): Promise<EmojiDto | undefined> => {
  try {
    const response = await apiClient(auth0).fetch(`/market/public-emoji/`, {
      body: JSON.stringify(createEmojiDto),
      method: "POST"
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

export const deleteEmoji = async (
  auth0: Auth0ContextInterface<User>,
  publicEmojiId: number
): Promise<PublicEmojiOutput | undefined> => {
  try {
    await apiClient(auth0).fetch(`/market/public-emoji/${publicEmojiId}`, {
      method: "DELETE"
    });
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
