import { useTitle } from "../../utils/useTitle";
import { useAuth0 } from "@auth0/auth0-react";
import { Loader } from "../layout/Loader";
import { Link, useNavigate } from "react-router-dom";
import { appRoutes } from "../../utils/routes";
import { useEffect } from "react";
import { trackEvent } from "../../utils/ga.utils";
import { Events } from "../../utils/events.utils";

const Home = () => {
  const navigate = useNavigate();
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();

  useTitle("EmojiBox - Get started");

  useEffect(() => {
    if (isAuthenticated) {
      navigate(appRoutes.COLLECTION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="py-16 bg-indigo-700">
          <div className="container m-auto px-6 space-y-8 text-gray-500 md:px-12">
            <div>
              <h2 className="font-sans text-3xl font-bold leading-tight tracking-tight text-white sm:text-4xl sm:text-center m-4">
                The essential app for Slack{" "}
                <span className="inline-block text-indigo-200">
                  Emoji obsessed teams
                </span>
              </h2>
            </div>
            <div className="mt-16 grid divide-x divide-y sm:grid-cols-3 lg:divide-y-0 lg:grid-cols-3 text-center">
              <div className="relative group bg-white m-4 rounded-xl">
                <div className="relative">
                  <img
                    src="/img/Announcement.gif"
                    className="w-full"
                    alt="desc"
                  />
                  <div className="space-y-2 py-4 px-8">
                    <h5 className="text-xl text-gray-800 font-bold">
                      Emoji announcements
                    </h5>
                    <p className="text-sm text-gray-600">
                      Notify everyone when a new Emoji gets added to Slack.{" "}
                      <strong>@EmojiBox subscribe</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative group bg-white m-4 rounded-xl">
                <div className="relative">
                  <img
                    src="/img/Description.gif"
                    className="w-full"
                    alt="desc"
                  />
                  <div className="space-y-2 py-4 px-8">
                    <h5 className="text-xl text-gray-800 font-bold">
                      Describe your emoji
                    </h5>
                    <p className="text-sm text-gray-600">
                      Document exactly what emoji mean in one spot so people
                      don't keep guessing.
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative group bg-white m-4 rounded-xl">
                <div className="relative">
                  <img src="/img/Lookup.gif" className="w-full" alt="desc" />
                  <div className="space-y-2 py-4 px-8">
                    <h5 className="text-xl text-gray-800 font-bold">
                      Emoji lookup
                    </h5>
                    <p className="text-sm text-gray-600">
                      Learn more about specific Emoji, either in public channels
                      or via DM. <strong>@EmojiBox :my-emoji:</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto text-center pt-8">
            <button
              className="bg-indigo-600 font-bold text-black rounded-md py-4 px-8 shadow-lg hover:opacity-80 block xs:inline-block my-4 mx-auto xs:m-4"
              style={{ backgroundColor: "#FFFFFF" }}
              onClick={() => {
                trackEvent(Events.SIGNIN_WITH_SLACK, undefined);
                loginWithRedirect();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  height: "24px",
                  width: "24px",
                  marginRight: "12px",
                  display: "inline-block"
                }}
                viewBox="0 0 122.8 122.8"
              >
                <path
                  d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                  fill="#e01e5a"
                ></path>
                <path
                  d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                  fill="#36c5f0"
                ></path>
                <path
                  d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                  fill="#2eb67d"
                ></path>
                <path
                  d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                  fill="#ecb22e"
                ></path>
              </svg>
              Sign in with Slack
            </button>
            <Link
              to={appRoutes.ABOUT}
              className="bg-indigo-700 inline-flex items-center justify-center px-6 py-4 text-base font-medium text-center text-white rounded-lg hover:text-opacity-75"
            >
              About EmojiBox
            </Link>
          </div>
          <div className="h-1 bg-indigo-700 rounded overflow-hidden m-12"></div>
          <section className="text-gray-600 body-font">
            <div>
              <h2 className="font-sans text-3xl font-bold leading-tight tracking-tight text-gray-900 sm:text-4xl sm:text-center m-4">
                <span className="inline-block text-white">How it works</span>
              </h2>
            </div>
            <div className="container px-5 mx-auto flex flex-wrap">
              <div className="flex relative pt-10 pb-20 sm:items-center md:w-2/3 mx-auto">
                <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                  <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">
                  1
                </div>
                <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                  <div className="flex-shrink-0 w-24 h-24 bg-gray-200 text-gray-500 rounded-full inline-flex items-center justify-center">
                    <h1 className="text-3xl">&#128274;</h1>
                  </div>
                  <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                    <h2 className="font-medium title-font text-white mb-1 text-xl">
                      Sign in with Slack
                    </h2>
                    <p className="leading-relaxed text-white">
                      First, tell us who you are by signing in with Slack. This
                      won't install anything in your workspace or alert any of
                      your colleagues.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
                <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                  <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">
                  2
                </div>
                <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                  <div className="flex-shrink-0 w-24 h-24 bg-gray-200 text-gray-500 rounded-full inline-flex items-center justify-center">
                    <h1 className="text-3xl">&#128190;</h1>
                  </div>
                  <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                    <h2 className="font-medium title-font text-white mb-1 text-xl">
                      Add EmojiBox to Slack
                    </h2>
                    <p className="leading-relaxed text-white">
                      Once logged in, you'll be prompted to add the EmojiBox
                      Slack Bot to your workspace. This will allow EmojiBox to
                      see your Emoji and interact with the channels you invite
                      it to.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
                <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                  <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">
                  3
                </div>
                <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                  <div className="flex-shrink-0 w-24 h-24 bg-gray-200 text-gray-500 rounded-full inline-flex items-center justify-center">
                    <h1 className="text-3xl">&#9989;</h1>
                  </div>
                  <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                    <h2 className="font-medium title-font text-white mb-1 text-xl">
                      You're ready to go!
                    </h2>
                    <p className="leading-relaxed text-white">
                      You're all set to manage your collection, describe your
                      Emoji and notify your team when new Emoji are added.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="text-center">
            <button
              className="bg-indigo-600 font-bold text-black rounded-md py-4 px-8 shadow-lg hover:opacity-80 block xs:inline-block my-4 mx-auto xs:m-4"
              style={{ backgroundColor: "#FFFFFF" }}
              onClick={() => {
                trackEvent(Events.SIGNIN_WITH_SLACK, undefined);
                loginWithRedirect();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  height: "24px",
                  width: "24px",
                  marginRight: "12px",
                  display: "inline-block"
                }}
                viewBox="0 0 122.8 122.8"
              >
                <path
                  d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                  fill="#e01e5a"
                ></path>
                <path
                  d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                  fill="#36c5f0"
                ></path>
                <path
                  d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                  fill="#2eb67d"
                ></path>
                <path
                  d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                  fill="#ecb22e"
                ></path>
              </svg>
              Try EmojiBox with Slack
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
