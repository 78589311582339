import { Auth0ContextInterface } from "@auth0/auth0-react";
import { Team } from "./types/team";

const AUTH0_SCOPE = "openid profile email";
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
const BASE_URL = process.env.REACT_APP_API_BASEURL;

export const apiClient = (auth0?: Auth0ContextInterface) => {
  return {
    // eslint-disable-next-line no-undef
    fetch: async (path: string, options?: RequestInit) => {
      const { headers, ...fetchOptions } = options ?? {};
      const accessToken = await auth0?.getAccessTokenSilently({
        audience: AUTH0_AUDIENCE,
        scope: AUTH0_SCOPE
      });
      const url = BASE_URL + (path.startsWith("/") ? path : "/" + path);
      return await fetch(url, {
        method: "GET",
        mode: "cors",
        ...fetchOptions,
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-team-id": getTeamIdHeaderValue(),
          Authorization: `Bearer ${accessToken}`,
          ...headers
        }
      });
    },
    fetchValue: async <V extends unknown>(
      path: string,
      // eslint-disable-next-line no-undef
      options?: RequestInit
    ): Promise<V> => {
      const res = await apiClient(auth0).fetch(path, options);
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return (await res.json()) as V;
    }
  };
};

const getTeamIdHeaderValue = () => {
  const teamJSON = localStorage.getItem("x-team");
  if (!teamJSON) {
    return "";
  }
  const team = JSON.parse(teamJSON) as Team;
  return team?.teamId?.toString() ?? "";
};
