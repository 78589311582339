import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { completeAuthFlow, uninstallBot } from "../../../api/accountApi";
import { refreshEmojis } from "../../../api/emojiApi";
import { useAppContext } from "../../../contexts/AppContext";
import { getOAuthState } from "../../../utils/oauthstate.util";
import { appRoutes } from "../../../utils/routes";
import { useTitle } from "../../../utils/useTitle";
import { AddToSlack } from "../../layout/AddToSlack";
import Alert from "../../layout/Alert";
import { Spinner } from "../../layout/Spinner";

export const Installation = () => {
  const { account, refreshAccount } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setAlert] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useTitle(`EmojiBox - Settings > Installation`);

  const auth0 = useAuth0();

  useEffect(() => {
    if (!account?.user) {
      return;
    }
    const expectedState = getOAuthState();
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    if (code && state === expectedState) {
      setIsLoading(true);
      searchParams.delete("code");
      searchParams.delete("state");
      setSearchParams(searchParams);
      (async () => {
        await completeAuthFlow(auth0, code);
        setAlert("EmojiBox has been installed in Slack!");
        await refreshAccount(auth0);
        await refreshEmojis(auth0);
        navigate(`${appRoutes.COLLECTION}?installationSuccess=true`);
      })();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  if (isLoading) {
    return (
      <div className="h-48 pb-10">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      {alert && (
        <div className="m-4">
          <Alert
            status="success"
            message={alert}
            handleAlertMessageClose={() => {
              setAlert(undefined);
            }}
          />
        </div>
      )}
      {!account?.installation && (
        <div className="text-center py-5">
          <AddToSlack buttonText="Install bot for Slack" />
        </div>
      )}
      {account?.installation && (
        <>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-bold text-gray-500">Date installed</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
              {new Date(
                account.installation.dateInstalled
              ).toLocaleDateString()}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-bold text-gray-500">Scopes</dt>
            <dd
              className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right"
              style={{ wordBreak: "break-all" }}
            >
              {account?.installation.scopes}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-bold text-gray-500">
              EmojiBox bot installation
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
              <div className="inline-block align-super my-3">
                <button
                  className="mx-2 -mt-2 border border-gray-600 p-3 rounded-md"
                  onClick={async () => {
                    setIsLoading(true);
                    await uninstallBot(auth0);
                    await refreshAccount(auth0);
                    setAlert("EmojiBox has been uninstalled.");
                  }}
                >
                  Uninstall EmojiBox
                </button>
              </div>

              <div className="inline-block my-3">
                <AddToSlack buttonText="Reinstall in workspace" />
              </div>
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-bold text-gray-500">
              Missing an Emoji?
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
              <button
                onClick={async () => {
                  setIsLoading(true);
                  await refreshEmojis(auth0);
                  setIsLoading(false);
                  setAlert("Your emoji have been synced from Slack");
                }}
                className="bg-white inline-flex items-center justify-center px-4 py-2 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 "
              >
                Trigger Emoji refresh
              </button>
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-bold text-gray-500">
              Delete, access, or transfer my data
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-right">
              <a
                href="mailto:data@emojibox.app?subject=Data request"
                className="bg-white inline-flex items-center justify-center px-4 py-2 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 "
              >
                Submit request
              </a>
            </dd>
          </div>
        </>
      )}
    </div>
  );
};
