const STATE_KEY = "EmojiBox_Oauth_State";

export const getOAuthState = () => {
  return localStorage.getItem(STATE_KEY);
};

export const setOAuthState = () => {
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let state = "";
  for (let i = 0; i < 40; i++) {
    state += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  state += "-addToSlack";

  localStorage.setItem(STATE_KEY, state);

  return state;
};
